.business-solution-block {
  padding-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.business-solution-block__title {
  max-width: 381px;
  font-family: 'Actay Wide', sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 169%;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: var(--white);
}

.business-solution-block__content {
  display: grid;
  grid-template-columns: repeat(3, 233px);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 80px;
}

.business-solution-block__icon {
  margin-bottom: 20px;
}

.business-solution-block__text {
  font-family: 'Actay Wide', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: -1.5px;
  color: var(--orange);
}

@media (max-width: 768px) {
  .business-solution-block {
    padding: 0 30px;
    padding-top: 20px;
    flex-direction: column;
  }

  .business-solution-block__title {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .business-solution-block__content {
    grid-template-columns: repeat(3, 220px);
    grid-row-gap: 40px;
  }

  .business-solution-block__text {
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  .business-solution-block {
    padding: 0 15px;
    margin-bottom: 30px;
  }

  .business-solution-block__title {
    font-size: 22px;
    line-height: 122%;
    margin-bottom: 25px;
  }

  .business-solution-block__content {
    grid-template-columns: repeat(2, 155px);
    grid-row-gap: 40px;
  }

  .business-solution-block__icon {
    margin-bottom: 10px;
  }

  .business-solution-block__icon img {
    width: 80px;
    height: auto;
  }

  .business-solution-block__text {
    font-size: 14px;
    line-height: 20px;
  }
}
