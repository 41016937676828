.details {
  margin-bottom: 175px;
}

table.iksweb {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  height: auto;
}

table.iksweb td,
table.iksweb th {
  padding: 15px 0;
  width: 30px;
  height: 35px;
}

table.iksweb th {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}

.uppercase {
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .details {
    padding: 0 30px;
    margin-bottom: 70px;
  }

  table.iksweb th {
    font-size: 16px;
    line-height: 19px;
  }
}

@media (max-width: 500px) {
  .details {
    padding: 0 15px;
    margin-bottom: 45px;
  }

  table.iksweb td {
    padding: 10px;
    font-size: 14px;
    line-height: 17px;
  }
}
