.not-found__content {
  padding-top: 210px;
  padding-bottom: 700px;
}

.not-found__title {
  margin-bottom: 30px;
  font-family: 'Actay Wide', sans-serif;
  font-weight: 700;
  font-size: 128px;
  line-height: 162px;
  color: var(--white);
  text-transform: uppercase;
}

.not-found__link {
  padding-left: 7px;
  padding-right: 7px;
  color: var(--orange);
}

.not-found__text {
  max-width: 860px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: var(--white);
}

.not-found__robot {
  position: absolute;
  top: 0px;
  right: 0px;
}

@media (max-width: 768px) {
  .not-found__content {
    padding: 0 30px;
    padding-top: 70px;
    padding-bottom: 200px;
  }

  .not-found__title {
    margin-bottom: 20px;
    font-size: 60px;
    line-height: 75px;
  }

  .not-found__text {
    font-size: 16px;
    line-height: 17px;
    max-width: 420px;
  }

  .not-found__robot {
    height: 550px;
    top: 40px;
  }
}

@media (max-width: 500px) {
  .not-found__content {
    padding: 0 15px;
    padding-bottom: 80px;
  }

  .not-found__title {
    margin-bottom: 10px;
    font-size: 36px;
    line-height: 45px;
  }

  .not-found__text {
    max-width: 182px;
  }

  .not-found__robot {
    height: 450px;
  }
}
