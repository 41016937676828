.services {
  margin-bottom: 210px;
}

.services__content {
  display: grid;
  grid-template-columns: 425px repeat(2, 344px);
  grid-template-rows: 270px 169px 219px;
  grid-column-gap: 28px;
  grid-row-gap: 28px;
}

.services__item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--black);
  border-radius: 20px;
  border: 2px solid var(--orange);
}

.services__item:hover {
  border-color: transparent;
}

.services__item:hover .services__subtitle {
  color: var(--white);
}

.services__subtitle {
  font-family: 'Actay Wide', sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 93%;
  color: var(--orange);
  text-transform: uppercase;
}

.services__first {
  grid-area: 1 / 1 / 3 / 2;
}

.services__first:hover {
  background: url('../../assets/services/services-block/services-1.png') no-repeat center;
  background-size: cover;
}

.services__second {
  grid-area: 1 / 2 / 2 / 3;
}

.services__second:hover {
  background: url('../../assets/services/services-block/services-2.png') no-repeat center;
  background-size: cover;
}

.services__third {
  grid-area: 1 / 3 / 2 / 4;
}

.services__third:hover {
  background: url('../../assets/services/services-block/services-3.png') no-repeat center;
  background-size: cover;
}

.services__fourth {
  grid-area: 2 / 2 / 3 / 3;
}

.services__fourth:hover {
  background: url('../../assets/services/services-block/services-4.png') no-repeat center;
  background-size: cover;
}

.services__fifth {
  grid-area: 2 / 3 / 4 / 4;
}

.services__fifth:hover {
  background: url('../../assets/services/services-block/services-5.png') no-repeat center;
  background-size: cover;
}

.services__sixth {
  grid-area: 3 / 1 / 4 / 3;
}

.services__sixth:hover {
  background: url('../../assets/services/services-block/services-6.png') no-repeat center;
  background-size: cover;
}

@media (max-width: 1600px) {
  .services {
    margin-bottom: 80px;
  }

  .services__content {
    grid-template-columns: 263px 213px 213px;
    grid-template-rows: 167px 104px 135px;
    grid-column-gap: 17px;
    grid-row-gap: 13px;
  }

  .services__subtitle {
    font-size: 19px;
    line-height: 30px;
  }
}

@media (max-width: 768px) {
  .services {
    padding: 0 30px;
    margin-bottom: 70px;
  }

  .services__content {
    display: grid;
    grid-template-columns: 255px 206px 206px;
    grid-template-rows: 162px 101px 131px;
    grid-column-gap: 17px;
    grid-row-gap: 13px;
  }
  .services__item {
    border: none;
  }

  .services__subtitle {
    font-size: 20px;
    line-height: 105%;
    color: var(--white);
  }

  .services__first {
    background: url('../../assets/services/services-block/services-1.png') no-repeat center;
    background-size: cover;
  }

  .services__second {
    background: url('../../assets/services/services-block/services-2.png') no-repeat center;
    background-size: cover;
  }

  .services__third {
    background: url('../../assets/services/services-block/services-3.png') no-repeat center;
    background-size: cover;
  }

  .services__fourth {
    background: url('../../assets/services/services-block/services-4.png') no-repeat center;
    background-size: cover;
  }

  .services__fifth {
    background: url('../../assets/services/services-block/services-5.png') no-repeat center;
    background-size: cover;
  }

  .services__sixth {
    background: url('../../assets/services/services-block/services-6.png') no-repeat center;
    background-size: cover;
  }
}

@media (max-width: 500px) {
  .services {
    padding: 0 15px;
    margin-bottom: 45px;
  }

  .services__content {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 111px 69px 90px 94px;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .services__first {
    grid-area: 1 / 1 / 3 / 2;
  }

  .services__second {
    grid-area: 1 / 2 / 2 / 3;
  }

  .services__third {
    grid-area: 4 / 1 / 5 / 2;
  }

  .services__fourth {
    grid-area: 2 / 2 / 3 / 3;
  }

  .services__fifth {
    grid-area: 4 / 2 / 5 / 3;
  }

  .services__sixth {
    grid-area: 3 / 1 / 4 / 3;
  }

  .services__item {
    border-radius: 12px;
  }

  .services__subtitle {
    font-size: 14px;
    line-height: 93%;
  }
}
