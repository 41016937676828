.about-items {
  display: grid;
  grid-template-columns: repeat(3, 352px);
  grid-template-rows: 277px;
  grid-column-gap: 50px;
  grid-row-gap: 0px;
  margin-bottom: 250px;
}

.about-items__item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 20px;
  border: 1px solid var(--orange);
}

.about-items__text {
  padding: 0 20px;
  font-family: 'Actay Wide', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 118%;
  text-transform: uppercase;
}

.about-items__item-first:hover {
  background: url('../../assets/about/about-items/about-items-1.png') no-repeat center;
  background-size: cover;
}

.about-items__item-second:hover {
  background: url('../../assets/about/about-items/about-items-2.png') no-repeat center;
  background-size: cover;
}

.about-items__item-third:hover {
  background: url('../../assets/about/about-items/about-items-3.png') no-repeat center;
  background-size: cover;
}

@media (max-width: 768px) {
  .about-items {
    padding: 0 30px;
    grid-template-columns: repeat(3, 212px);
    grid-template-rows: 167px;
    grid-column-gap: 28px;
    grid-row-gap: 0px;
    margin-bottom: 70px;
  }

  .about-items__text {
    font-size: 16px;
    line-height: 118%;
  }

  .about-items__item-first {
    background: url('../../assets/about/about-items/about-items-1.png') no-repeat center;
    background-size: cover;
  }

  .about-items__item-second {
    background: url('../../assets/about/about-items/about-items-2.png') no-repeat center;
    background-size: cover;
  }

  .about-items__item-third {
    background: url('../../assets/about/about-items/about-items-3.png') no-repeat center;
    background-size: cover;
  }
}

@media (max-width: 500px) {
  .about-items {
    padding: 0 30px;
    grid-template-columns: 212px;
    grid-template-rows: repeat(3, 167px);
    grid-column-gap: 0px;
    grid-row-gap: 20px;
    margin-bottom: 45px;
    justify-content: center;
  }
}
