.about {
  position: relative;
  padding-top: 110px;
  margin-bottom: 220px;
}

.about__title {
  max-width: 999px;
  font-family: 'Actay Wide', sans-serif;
  font-weight: 700;
  font-size: 80px;
  line-height: 90px;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.about__text {
  max-width: 593px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
}

.about__img {
  position: absolute;
  top: -40px;
  right: 0px;
}

.about-img__mobile {
  display: none;
}

.about__img-tablet {
  display: none;
}

@media (max-width: 768px) {
  .about {
    margin-bottom: 70px;
  }

  .about__title {
    font-size: 57px;
    line-height: 70px;
  }

  .about__layot {
    padding: 0 30px;
  }

  .about__text {
    font-size: 24px;
    line-height: 29px;
    max-width: 338px;
  }

  .about__img {
    display: none;
  }

  .about__img-tablet {
    position: absolute;
    right: 0px;
    bottom: -125px;
  }
}

@media (max-width: 500px) {
  .about {
    padding-top: 20px;
    margin-bottom: 45px;
  }

  .about__layot {
    padding: 0 15px;
  }

  .about__title {
    font-size: 27px;
    line-height: 35px;
    margin-bottom: 15px;
  }

  .about__text {
    font-size: 20px;
    line-height: 24px;
    max-width: 233px;
  }

  .about-img__mobile {
    display: block;
    position: absolute;
    right: 0px;
    bottom: 80px;
  }

  .about__img-tablet {
    display: none;
  }
}
