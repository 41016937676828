.layout {
  max-width: 1166px;
  margin: 0 auto;
}

@media (max-width: 1600px) {
  .layout {
    max-width: 791px;
  }
}

@media (max-width: 768px) {
  .layout {
    max-width: 100%;
  }
}
