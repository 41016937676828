.text {
  margin-bottom: 50px;
  max-width: 1041px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--white);
}

@media (max-width: 768px) {
  .text {
    padding: 0 30px;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

@media (max-width: 500px) {
  .text {
    padding: 0 15px;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 25px;
  }
}
