.trust {
  padding: 60px 70px;
  border: 1px solid var(--orange);
  border-radius: 80px;
  margin-bottom: 295px;
  padding-bottom: 110px;
}

.trust__content {
  display: grid;
  grid-template-columns: 255px 244px 266px;
  grid-template-rows: 73px 150px 116px;
  grid-column-gap: 130px;
  grid-row-gap: 60px;
}

.trust__title {
  margin-bottom: 75px;
}

.trust__item {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .trust__container {
    padding: 0 30px;
  }

  .trust {
    padding: 40px;
    margin-bottom: 70px;
    border-radius: 30px;
  }

  .trust__title {
    margin-bottom: 25px;
  }

  .trust__content {
    grid-template-columns: 153px 112px 160px;
    grid-template-rows: 44px 77px 70px;
    grid-column-gap: 90px;
    grid-row-gap: 40px;
  }
}

@media (max-width: 500px) {
  .trust__container {
    padding: 0 15px;
  }

  .trust {
    padding: 20px;
    margin-bottom: 30px;
  }

  .trust__title {
    margin-bottom: 20px;
  }

  .trust__content {
    grid-template-columns: 136px 128px;
    grid-template-rows: 37px 37px 65px 59px;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }

  .trust__item svg {
    max-height: 100%;
  }
}
