@import url('https://fonts.googleapis.com/css2?family=Inter:opsz,wght@14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100..900&display=swap');

@font-face {
  font-family: 'Actay Wide';
  font-style: normal;
  font-weight: 700;
  src: local('Actay Wide'), url('../fonts/ActayWide-Bold.woff2') format('woff2'),
    url('../fonts/ActayWide-Bold.woff') format('woff');
}

:root {
  --black: #000000;
  --orange: #fe701e;
  --white: #ffffff;
}

body {
  padding: 0;
  margin: 0;
  font-size: 24px;
  line-height: 29px;
  background-color: var(--black);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  overflow-x: hidden;
}

* {
  color: var(--white);
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
}

h1,
h2,
h3,
p {
  margin: 0;
  padding: 0;
}

.paragraph-block {
  margin-bottom: 40px;
}

.paragraph {
  margin-bottom: 15px;
  max-width: 1041px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--white);
}

.title-block {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 48px;
  line-height: 59px;
  color: var(--white);
  margin-bottom: 55px;
  text-transform: uppercase;
}

.about-main-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 48px;
  line-height: 59px;
  text-transform: uppercase;
  color: var(--orange);
}

@media (max-width: 768px) {
  .title-block {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 25px;
  }

  .paragraph-block {
    padding: 0 30px;
    margin-bottom: 30px;
  }

  .paragraph {
    margin-bottom: 0px;
    font-size: 20px;
    line-height: 24px;
  }
}

@media (max-width: 500px) {
  .paragraph-block {
    padding: 0 15px;
    margin-bottom: 20px;
  }

  .paragraph {
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 20px;
  }

  .about-main-subtitle {
    font-size: 30px;
    line-height: 37px;
  }

  .title-block {
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 20px;
  }
}
