.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 80px 0;
}

@media (max-width: 768px) {
  .header {
    padding: 50px 30px;
  }
}

@media (max-width: 500px) {
  .header {
    padding: 30px 15px;
  }
}
