.mission {
  display: flex;
  margin-bottom: 145px;
}

.mission__img {
  margin-right: 50px;
}

.mission__title {
  margin-bottom: 100px;
}

.mission__text {
  max-width: 588px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 34px;
  line-height: 41px;
}

.mission__img-mobile {
  display: none;
}

@media (max-width: 768px) {
  .mission {
    padding: 0 30px;
    margin-bottom: 70px;
  }

  .mission__title {
    margin-bottom: 25px;
  }

  .mission__text {
    max-width: 401px;
    font-size: 20px;
    line-height: 24px;
  }

  .mission__img {
    width: 286px;
    height: 307px;
  }
}

@media (max-width: 500px) {
  .mission {
    display: block;
    padding: 0 15px;
    margin-bottom: 45px;
  }

  .mission__img {
    display: none;
  }

  .mission__img-mobile {
    display: block;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .mission__text {
    max-width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}
