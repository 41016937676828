.why-us {
  margin-bottom: 210px;
}

.why-us__content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 277px);
  grid-column-gap: 55px;
  grid-row-gap: 110px;
}

.why-us__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid var(--orange);
  border-radius: 20px;
}

.why-us__item:hover .why-us__text {
  color: var(--orange);
}

.why-us__item svg path {
  transition: fill 2s ease-in-out;
}

.why-us__item:hover svg path {
  fill: var(--orange);
}

.why-us__text {
  max-width: 240px;
  padding-top: 20px;
  font-family: 'Inter', sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  color: var(--white);
  text-transform: uppercase;
  transition: color 2s ease-in-out;
}

@media (max-width: 1600px) {
  .why-us__content {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 171px);
    grid-column-gap: 30px;
    grid-row-gap: 70px;
  }

  .why-us__item svg {
    width: 67px;
    height: auto;
  }

  .why-us__text {
    font-size: 12px;
    line-height: 15px;
  }
}

@media (max-width: 768px) {
  .why-us {
    padding: 0 30px;
    margin-bottom: 70px;
  }

  .why-us__content {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 254px);
    grid-column-gap: 55px;
    grid-row-gap: 55px;
  }

  .why-us__item {
    border-radius: 18px;
  }

  .why-us__text {
    font-size: 18px;
    line-height: 22px;
    color: var(--orange);
  }

  .why-us__item svg {
    width: 100px;
    height: auto;
  }

  .why-us__item svg path {
    fill: var(--orange);
  }
}

@media (max-width: 500px) {
  .why-us {
    padding: 0 15px;
    margin-bottom: 45px;
  }

  .why-us__content {
    grid-template-rows: repeat(3, 160px);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
  }

  .why-us__item {
    border-radius: 15px;
  }

  .why-us__text {
    font-size: 14px;
    line-height: 17px;
    max-width: 140px;
  }

  .why-us__item svg {
    width: 68px;
    height: auto;
  }
}
