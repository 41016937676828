.contact__button {
  padding: 16px 19px;
  background-color: var(--orange);
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--white);
  border: 2px solid var(--orange);
  border-radius: 85px;
  transition: all 1s ease-in-out;
}

.contact__button:hover {
  background-color: var(--white);
  color: var(--orange);
}

@media (max-width: 768px) {
  .contact__button {
    padding: 12px 15px;
    font-size: 16px;
    line-height: 19px;
    border-radius: 58px;
  }
}

@media (max-width: 500px) {
  .contact__button {
    padding: 11px 9px;
  }
}
