.menu {
  display: flex;
  align-items: center;
}

.menu__item:not(:last-child) {
  margin-right: 60px;
}

.menu__link {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
}

@media (max-width: 1600px) {
  .menu__link {
    font-size: 24px;
    line-height: 28px;
  }
}

@media (max-width: 768px) {
  .menu__link {
    font-size: 16px;
    line-height: 19px;
  }
}

@media (max-width: 500px) {
  .menu__item:not(:last-child) {
    margin-right: 10px;
  }
}
