.why-virtek {
  margin-bottom: 247px;
}

.why-virtek__title {
  margin-bottom: 35px;
}

.why-virtek__content {
  border-top: 1px solid var(--orange);
  border-bottom: 1px solid var(--orange);
}

.why-virtek__items {
  display: grid;
  grid-template-columns: 352px repeat(2, 295px) 375px;
  grid-template-rows: 298px 232px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.why-virtek__item {
  padding: 50px 0;
}

.why-virtek__item-first {
  border-left: 1px solid var(--orange);
  grid-area: 1 / 1 / 3 / 2;
  border-right: 1px solid var(--orange);
  padding-right: 15px;
  padding-left: 15px;
}

.why-virtek__text-main {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 30px;
  line-height: 37px;
  color: var(--orange);
  margin-bottom: 40px;
}

.why-virtek__button {
  padding: 10px 20px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 37px;
  color: var(--orange);
  border: 2px solid var(--orange);
  border-radius: 85px;
}

.why-virtek__subtitle {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  margin-bottom: 9px;
}

.why-virtek__text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.why-virtek__item-second {
  grid-area: 1 / 2 / 2 / 3;
  padding-left: 30px;
  padding-right: 30px;
  border-right: 1px solid var(--orange);
}

.why-virtek__item-third {
  grid-area: 1 / 3 / 2 / 4;
  padding-left: 30px;
  padding-right: 30px;
  border-right: 1px solid var(--orange);
}

.why-virtek__item-fourth {
  grid-area: 1 / 4 / 2 / 5;
  padding-left: 30px;
  padding-right: 30px;
  border-right: 1px solid var(--orange);
}

.why-virtek__item-fifth {
  grid-area: 2 / 2 / 3 / 4;
  border-top: 1px solid var(--orange);
  border-right: 1px solid var(--orange);
  padding-left: 30px;
  padding-right: 30px;
}

.why-virtek__item-sixth {
  border-top: 1px solid var(--orange);
  grid-area: 2 / 4 / 3 / 5;
  padding-left: 30px;
  border-right: 1px solid var(--orange);
}

.why-virtek__button-mobile-bottom {
  display: none;
}

@media (max-width: 768px) {
  .why-virtek {
    margin-bottom: 70px;
  }

  .why-virtek__title {
    padding: 0 30px;
  }

  .why-virtek__items {
    display: grid;
    grid-template-columns: 283px repeat(2, 228px);
    grid-template-rows: repeat(2, 170px) 115px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .why-virtek__item {
    padding: 30px 0;
  }

  .why-virtek__text-main {
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 50px;
  }

  .why-virtek__button {
    padding: 10px 12px;
    border-radius: 60px;
    font-size: 22px;
    line-height: 28px;
  }

  .why-virtek__item-first {
    grid-area: 1 / 1 / 4 / 2;
    padding-left: 30px;
  }

  .why-virtek__subtitle {
    font-size: 16px;
    line-height: 20px;
  }

  .why-virtek__text {
    font-size: 14px;
    line-height: 17px;
  }

  .why-virtek__item-second {
    grid-area: 1 / 2 / 2 / 3;
    padding: 15px;
  }

  .why-virtek__item-third {
    grid-area: 1 / 3 / 2 / 4;
    padding: 15px;
  }

  .why-virtek__item-fourth {
    border-top: 1px solid var(--orange);
    grid-area: 2 / 3 / 3 / 4;
    padding: 15px;
  }

  .why-virtek__item-fifth {
    grid-area: 3 / 2 / 4 / 4;
    padding: 15px;
  }

  .why-virtek__item-sixth {
    grid-area: 2 / 2 / 3 / 3;
    padding: 15px;
  }
}

@media (max-width: 500px) {
  .why-virtek {
    margin-bottom: 30px;
  }

  .why-virtek__title {
    border-top: 1px solid var(--orange);
    padding: 15px;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }

  .why-virtek__content {
    border-top: none;
  }

  .why-virtek__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 160px 200px 270px 190px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .why-virtek__item-first {
    grid-area: 1 / 1 / 2 / 3;
    padding: 0px 15px 20px 15px;
    border-bottom: 1px solid var(--orange);
    border-right: none;
    border-left: none;
  }

  .why-virtek__item-second {
    grid-area: 2 / 1 / 3 / 2;
    padding: 20px 15px;
  }

  .why-virtek__item-third {
    grid-area: 2 / 2 / 3 / 3;
    padding: 20px 10px;
    border-right: none;
  }

  .why-virtek__item-fourth {
    border-top: 1px solid var(--orange);
    grid-area: 3 / 1 / 4 / 2;
    padding: 20px 15px;
  }

  .why-virtek__item-fifth {
    grid-area: 3 / 2 / 4 / 3;
    padding: 20px 15px;
    border-right: none;
  }

  .why-virtek__item-sixth {
    grid-area: 4 / 1 / 5 / 3;
    padding: 20px 15px;
    border-right: none;
  }

  .why-virtek__text-main {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0px;
  }

  .why-virtek__button-mobile-top {
    display: none;
  }

  .why-virtek__button-mobile-bottom {
    display: block;
    margin-top: 25px;
    font-size: 20px;
    line-height: 24px;
  }

  .why-virtek__subtitle {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 5px;
  }

  .why-virtek__text {
    font-size: 14px;
    line-height: 17px;
  }
}
