.intro {
  margin-bottom: 150px;
}

.intro__img-content {
  position: relative;
  padding-top: 30px;
}

.intro__name {
  position: absolute;
  bottom: -35px;
}

.intro__title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 48px;
  line-height: 59px;
  margin-bottom: 5px;
}

.intro__text {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 45px;
}

.intro__button {
  padding: 16px 40px;
  background-color: var(--orange);
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: var(--white);
  border: 2px solid var(--orange);
  border-radius: 85px;
  transition: all 1s ease-in-out;
}

.intro__button:hover {
  background-color: var(--white);
  color: var(--orange);
}

@media (max-width: 1600px) {
  .intro {
    margin-bottom: 350px;
  }

  .intro__name {
    bottom: -195px;
  }
}

@media (max-width: 768px) {
  .intro {
    padding: 0 30px;
  }

  .intro__img-content {
    padding-top: 0px;
  }

  .intro__img {
    width: 100%;
  }

  .intro__name {
    bottom: -75px;
  }

  .intro__title {
    font-size: 32px;
    line-height: 40px;
  }

  .intro__text {
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 25px;
  }

  .intro__button {
    padding: 12px 30px;
    font-size: 16px;
    line-height: 19px;
  }
}

@media (max-width: 500px) {
  .intro {
    padding: 0 15px;
  }

  .intro__name {
    bottom: -165px;
  }

  .intro__button {
    padding: 11px 15px;
  }
}
