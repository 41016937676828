.description {
  margin-bottom: 55px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  color: var(--white);
  max-width: 957px;
}

@media (max-width: 768px) {
  .description {
    font-size: 26px;
    line-height: 32px;
    max-width: 100%;
    padding: 0 30px;
    margin-bottom: 50px;
  }
}

@media (max-width: 500px) {
  .description {
    font-size: 19px;
    line-height: 23px;
    padding: 0 15px;
    margin-bottom: 20px;
  }
}
