.block {
  padding-top: 40px;
  padding-bottom: 200px;
}

.block__img {
  margin-bottom: 55px;
}

@media (max-width: 768px) {
  .block {
    padding-bottom: 70px;
  }

  .block__img {
    margin-bottom: 50px;
    width: 100%;
    height: auto;
  }
}

@media (max-width: 500px) {
  .block {
    padding-top: 20px;
    padding-bottom: 50px;
  }

  .block__img {
    margin-bottom: 25px;
  }
}
