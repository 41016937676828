.items {
  margin-bottom: 100px;
}

.items__content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 88px;
  grid-row-gap: 0px;
}

.items__text {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
}

.items__img {
  margin-bottom: 40px;
}

@media (max-width: 1600px) {
  .items {
    margin-bottom: 200px;
  }

  .items__content {
    grid-template-columns: repeat(4, 153px);
    grid-template-rows: 214px;
    grid-column-gap: 36px;
  }

  .items__img {
    width: 100%;
    height: auto;
  }

  .items__text {
    font-size: 20px;
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  .items {
    padding: 0 30px;
    padding-top: 140px;
    margin-bottom: 70px;
  }

  .items__content {
    grid-column-gap: 25px;
  }

  .items__text {
    font-size: 20px;
    line-height: 24px;
  }

  .items__img {
    width: 152px;
    height: auto;
    margin-bottom: 25px;
  }
}

@media (max-width: 500px) {
  .items {
    padding: 0 15px;
    padding-top: 80px;
    margin-bottom: 45px;
  }

  .items__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .items__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .items__text {
    font-size: 14px;
    line-height: 17px;
  }

  .items__img {
    width: 103px;
    height: auto;
    margin-bottom: 15px;
  }
}
