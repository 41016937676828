.map {
  padding: 235px 0;
  width: 100%;
  background: url('../../assets/map/map.png') no-repeat center;
  background-size: cover;
}

.map__content {
  max-width: 777px;
  padding: 70px 90px 100px 50px;
  background-color: var(--black);
  border-radius: 20px;
}

.map__title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 64px;
  line-height: 78px;
  color: var(--orange);
  margin-bottom: 30px;
}

.map__text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
}

@media (max-width: 768px) {
  .map {
    padding: 50px 0;
    background: url('../../assets/map/map-table.png') no-repeat center;
  }

  .map__layout {
    padding: 0 30px;
  }

  .map__content {
    max-width: 439px;
    padding: 40px 50px 60px 30px;
    background-color: var(--black);
    border-radius: 20px;
  }

  .map__title {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 20px;
  }

  .map__text {
    font-size: 20px;
    line-height: 34px;
  }
}
@media (max-width: 500px) {
  .map {
    padding-top: 44px;
    padding-bottom: 73px;
  }

  .map__layout {
    padding: 0 15px;
  }

  .map__content {
    max-width: 217px;
    padding: 20px 20px 35px 20px;
    border-radius: 14px;
  }

  .map__title {
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 10px;
  }

  .map__text {
    font-size: 14px;
    line-height: 17px;
  }
}
