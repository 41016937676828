.title {
  margin-bottom: 25px;
  font-family: 'Actay Wide', sans-serif;
  font-weight: 700;
  font-size: 74px;
  line-height: 91%;
  letter-spacing: 1.5px;
  color: var(--white);
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .title {
    padding: 0 30px;
    font-size: 40px;
    line-height: 99%;
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .title {
    padding: 0 15px;
    font-size: 30px;
    line-height: 102%;
    margin-bottom: 15px;
  }
}
