.form {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  top: 0px;
  left: 0px;
  align-items: center;
  justify-content: center;
  background-color: rgba(149, 145, 145, 0.7);
  z-index: 999999999999999;
  overflow: hidden;
}

.form-content {
  position: relative;
  padding: 38px;
  max-width: 500px;
  background-color: #fff;
  border-radius: 20px;
}

.form-title {
  max-width: 350px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 115%;
  margin-bottom: 35px;
  color: #21242e;
}

.form-btn-close {
  position: absolute;
  top: 15px;
  right: 20px;
}

.form-input {
  display: block;
  padding: 18px 24px;
  width: 100%;
  background-color: var(--white);
  margin-bottom: 20px;
  border: 1px solid rgba(33, 36, 46, 0.4);
  font-size: 16px;
  line-height: 120%;
  color: rgba(33, 36, 46, 0.4);
  border-radius: 16px;
}

.form-checkbox {
  display: flex;
  align-items: start;
  margin-bottom: 25px;
}

.form-checkbox label {
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #21242e;
}

.form-btn {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  padding: 18px 38px;
  border-radius: 1000px;
  background-color: var(--orange);
  font-size: 16px;
  line-height: 120%;
  color: var(--white);
  border: none;
}

.form-not-active {
  display: none;
}

.checkbox__politika {
  color: var(--orange);
}

@media (max-width: 500px) {
  .form-content {
    padding: 35px 15px 15px 15px;
    max-width: 266px;
    border-radius: 11px;
  }

  .form-title {
    font-size: 12px;
    margin-bottom: 30px;
  }

  .form-btn-close {
    top: 20px;
    right: 15px;
  }

  .form-btn-close svg {
    width: 18px;
    height: 18px;
  }

  .form-input {
    padding: 10px 15px;
    margin-bottom: 15px;
    font-size: 10px;
  }

  .form-checkbox label {
    font-size: 10px;
  }

  .form-btn {
    padding: 5px 20px;
    border-radius: 15px;
    font-size: 13px;
  }
}
