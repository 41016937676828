.footer {
  padding-top: 131px;
  padding-bottom: 83px;
  background: url('../../assets/footer/footer.png') no-repeat center;
  background-size: cover;
  width: 100%;
}

.footer__content {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.footer__text {
  max-width: 388px;
  display: block;
  margin-bottom: 25px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}

.footer__media {
  padding-top: 20px;
}

.footer__media-link:not(:last-child) {
  margin-right: 15px;
}

.footer-table__content {
  display: none;
}

@media (max-width: 768px) {
  .footer {
    padding: 50px 30px;
  }

  .footer__content {
    display: none;
  }

  .footer-table__content {
    display: flex;
    justify-content: space-between;
  }

  .footer__link {
    margin-top: 48px;
  }

  .footer__text {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 500px) {
  .footer {
    padding: 46px 15px;
  }

  .footer-table__content {
    flex-direction: column;
  }

  .footer__text {
    margin-bottom: 10px;
  }

  .footer__link {
    margin-top: 10px;
  }

  .footer__link svg {
    width: 38px;
    height: auto;
  }
}
