.calculatecostbutton {
  margin-top: 20px;
  padding: 10px 30px;
  background-color: transparent;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: var(--orange);
  border: 2px solid var(--orange);
  border-radius: 85px;
  transition: all 1s ease-in-out;
}

@media (max-width: 768px) {
  .calculatecostbutton {
    margin-left: 30px;
    padding: 10px 25px;
    margin-top: 0px;
    font-size: 24px;
    line-height: 29px;
  }
}

@media (max-width: 500px) {
  .calculatecostbutton {
    margin-left: 15px;
    padding: 10px 15px;
    font-size: 20px;
    line-height: 24px;
  }
}
